
$(document).ready(function()
{
    $('.dropdown-menu').on('click', function(event){
        event.stopPropagation();
    });

    

    if($('#contact-card').length)
    {
        $('#contact-card').scrollToFixed({
            marginTop: 10,
            limit: $('footer').offset().top
        });
    }

    $('#destination-li').on('click', function(event){
        $('.dd').first().toggleClass('show');
    });

    $('.dropdown-submenu').click(function()
    {
        $(this).find('ul').addClass('show');
        $(this).addClass('li-active');
        $('.dropdown-submenu').not(this).find('ul').removeClass('show');
        $('.dropdown-submenu').not(this).removeClass('li-active');
    });  
 
        addBg();
    
});


    $(window).load(function () {
        if($('#contact').length)
        {
            $('#contact').scrollToFixed({
                marginTop: 10,
                limit: $('.limit').offset().top - $('#contact').outerHeight(true)
            });   
        }
    });


function switchCategoriasAtractivo()
{
    $.ajax({
        url: '/api/categorias-atractivo/switch',
        type: 'GET',
        beforeSend: function()
        {
            //
        },
        success: function (data) {
            var data = data['data'];
            
            $('#cycler .col').each(function(i, item)
            {

                elemento = data[i];
                loadImage(elemento.icono_blanco);
                loadImage(elemento.img_med)
                $(item).find('h3').text(elemento.nombre_plural);
                $(item).find('.attraction-description img').attr('src', elemento.icono_blanco);
                $(item).find('img').last().attr('src', elemento.img_med);
            });
        },
    });
}

function loadImage(path)
{
    $('<img src="'+ path +'">').one("load", function() {
      return true;
    });
}


function addBg()
{
    if($('#bg-header').length)
    {
        ruta = '';
        switch (true)
        {
            case $('#bg-header').hasClass('bg-events'):
                ruta = "../img/bg/eventos/eventos-en-rocha.jpg";
                break;
            case $('#bg-header').hasClass('bg-tarjeta'):
                ruta = "../img/bg/tarjeta-rocha.jpg";
                break;
            case $('#bg-header').hasClass('bg-horarios'):
                ruta = "../img/bg/horarios-omnibus-rocha.jpg";
                break;
            case $('#bg-header').hasClass('bg-horarios-cabo'):``
                ruta = "../img/bg/horarios-cabo-polonio.jpg";
                break;
            case $('#bg-header').hasClass('bg-ogd'):
                ruta = "../img/bg/ogd-rocha.jpg";
                break;
            case $('#bg-header').hasClass('bg-municipios'):
                ruta = "../img/bg/municipios.png";
                break;
            case $('#bg-header').hasClass('bg-clima'):
                ruta = "../img/bg/clima-rocha.jpg";
                break;
            case $('#bg-header').hasClass('bg-promociones'):
                ruta = "../img/bg/promociones-en-rocha.jpg";
                break;
        }
        $('#bg-header').css('background-image', 'url('+ruta+')');  
    }
}


$('#inc-font').click(function()
{   
    curSize = parseInt($('body').css('font-size')) + 2;
    if(curSize <= 20)
    {
        $('body').css('font-size', curSize);
    }
}); 

$('#dec-font').click(function()
{   
    curSize= parseInt($('body').css('font-size')) - 2;
    if(curSize >= 12)
    {
        $('body').css('font-size', curSize);
    }
});